import React from "react";
import { navigationPathes } from "./NavigationItems";
import "../Styles/HeaderNavigation.css";
import { useNavigate } from "react-router-dom";
import { Card, ListItem, ListItemText, Typography } from "@mui/material";

function HeaderNavigation() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    hidden: true,
  });

  const logins = [
    {
      title: "Investor",
      path: `https://investor.tenack.com`,
    },
    {
      title: "Tradesman",
      path: `https://tradesman.tenack.com`,
    },
    {
      title: "Tenant",
      path: `https://tenant.tenack.com`,
    },
    {
      title: "Field",
      path: `https://field.tenack.com`,
    },
  ];

  function Popper() {
    return (
      <div
        className="LoginPopper"
        style={{ visibility: state.hidden ? "hidden" : "visible" }}
      >
        <Card>
          {logins &&
            logins.map((item, ind) => (
              <ListItem
                button
                dense
                key={ind}
                onClick={(e) => {
                  window.open(item.path);
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      {item.title}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
        </Card>
      </div>
    );
  }
  return (
    <>
      <div className="HeaderNavigation">
        <div
          className="NavItem"
          onClick={(e) => {
            setState((state) => ({
              ...state,
              hidden: !state.hidden,
            }));
          }}
        >
          <div className="text">{"Login"}</div>
          <Popper />
        </div>

        {navigationPathes &&
          navigationPathes.map((item, ind) => (
            <div
              className="NavItem"
              key={ind}
              onClick={(e) => {
                if (item.item) {
                  if (document.getElementById(item.item)) {
                    document
                      .getElementById(item.item)
                      .scrollIntoView({ behavior: "smooth" });
                  } else {
                    navigate(item.path);
                  }
                } else {
                  navigate(item.path);
                }
              }}
            >
              <div className="text">{item.title}</div>
            </div>
          ))}
      </div>
    </>
  );
}

export default HeaderNavigation;
