import React from "react";
import "./Styles/PricingPage.css";
import { Button, Card, Switch } from "@mui/material";
import styled from "@emotion/styled";
import funkyarrow from "../Images/Photos/arrow.png";
import useWindowDimensions from "../Hooks/WindowSize";
import CheckIcon from "@mui/icons-material/Check";
import {Helmet} from 'react-helmet'



const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "rgb(18, 100, 100)",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "rgb(18, 100, 100)",
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };

function PricingPage() {
  const size = useWindowDimensions();

  const [state, setState] = React.useState({
    loading: false,
    tradesman: false,
    monthly: true,
    tradesmanMonth: true,
    tradesmanAmount: 50,
    investorAmount: 10,
    investorMonth: true,
  });

  const tradesmanArr = [
    "Retain Customers",
    "Reduce Paperwork",
    "Access Asset Service History",
    "Reduce Quoting Time",
    "Reduced Unpaid Callouts",
    "Increased Accountability",
    "Stand Out From Your Competitors",
  ];

  const investorArr = [
    "Keep track of rental properties",
    "Reduce maintenace request wait time",
    "Increased Tradesman accountability",
    "Reduced Paperwork",
    "Easily Track Expenses",
    "Access to full maintenace history",
  ];

  React.useEffect(() => {
    if (state.tradesmanMonth) {
      setState((state) => ({
        ...state,
        tradesmanAmount: 50,
      }));
    } else {
      setState((state) => ({
        ...state,
        tradesmanAmount: 50 * 12 * 0.8,
      }));
    }
  }, [state.tradesmanMonth]);

  React.useEffect(() => {
    if (state.investorMonth) {
      setState((state) => ({
        ...state,
        investorAmount: 10,
      }));
    } else {
      setState((state) => ({
        ...state,
        investorAmount: 10 * 12 * 0.8,
      }));
    }
  }, [state.investorMonth]);

  const tradesman = React.useRef();
  const investor = React.useRef();

  return (
    <div className="PricingPage">
       <Helmet>
<meta charSet="utf-8" name='Tenack Property Maintenace Software Pricing' content="Tenack is a property maintenance app that makes it easy to schedule and manage your home repairs.
We help you handle the hassle of fixing things around the house by providing you with an easy way to book appointments with local professionals who are qualified and licensed to handle anything from plumbing repairs to electrical work." />
<title>Tenack | Pricing</title>
<link rel="canonical" href="http://tenack.com/pricing"  />
</Helmet>

      <div className="hero">
        <div className="message">
          Ready To Get Started with tenack?{" "}
          <div>
            <Button
              style={{
                color: "white",
                borderRight: "solid 1px",
                borderRadius: "0px",
              }}
              onClick={(e) => {
                investor.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Investor
            </Button>
            <Button
              style={{ color: "white" }}
              onClick={(e) => {
                tradesman.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Tradesman
            </Button>
          </div>
        </div>

        <div className="arrow-comment">Still got questions?</div>

        <img src={funkyarrow} alt="" className="arrow" />
      </div>

      {size.width > 880 ? (
        <div className="tradesman-section">
          <div className="card" ref={tradesman}>
            <div className="switch">
              <div className="label">Monthy</div>
              <GreenSwitch
                value={state.tradesmanMonth}
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    tradesmanMonth: !state.tradesmanMonth,
                  }));
                }}
              />
              <div className="label">Yearly</div>
            </div>

            <div className="title">tradesman</div>

            <div className="amount-div">
              <div className="amount">
                <div className="symbol">$</div>
                <div className="number">{state.tradesmanAmount}</div>

                <div className="save">
                  {state.tradesmanMonth ? "" : "Save 20%"}
                </div>
              </div>
            </div>
            <div className="per-div">
              {state.tradesmanMonth ? "Per Month" : "Per Year"}
            </div>

            <div className="subtitle-features" />

            <div className="list">
              {tradesmanArr.map((item, ind) => (
                <div className="list-item" key={ind}>
                  <div className="icon">
                    <CheckIcon
                      fontSize="medium"
                      style={{ color: "rgb(18, 100, 100)" }}
                    />
                  </div>
                  <div className="text">{item}</div>
                </div>
              ))}
            </div>

            <div className="button-div">
              <Button
                style={{
                  backgroundColor: "rgb(18, 100, 100)",
                  color: "white",
                  fontWeight: "700",
                }}
                onClick={((e) => {
                    window.open('https://tradesman.tenack.com/signup')
                })}
              >
                Sign Up
              </Button>
            </div>
          </div>
          <div className="card" ref={investor}>
            <div className="switch">
              <div className="label">Monthy</div>
              <GreenSwitch
                value={state.investorMonth}
                onChange={(e) =>
                  setState({
                    ...state,
                    investorMonth: !state.investorMonth,
                  })
                }
              />
              <div className="label">Yearly</div>
            </div>

            <div className="title">investor</div>
            <div className="amount-div">
              <div className="amount">
                <div className="symbol">$</div>
                <div className="number">{state.investorAmount}</div>
                <div className="save">
                  {state.investorMonth ? "" : "Save 20%"}
                </div>
              </div>
            </div>
            <div className="per-div">
              {state.investorMonth ? "Per Month" : "Per Year"}
            </div>
            <div className="subtitle-features" />

            <div className="list">
              {investorArr.map((item, ind) => (
                <div className="list-item" key={ind}>
                  <div className="icon">
                    <CheckIcon
                      fontSize="medium"
                      style={{ color: "rgb(18, 100, 100)" }}
                    />
                  </div>
                  <div className="text">{item}</div>
                </div>
              ))}
            </div>
            <div className="button-div">
              <Button
                style={{
                  backgroundColor: "rgb(18, 100, 100)",
                  color: "white",
                  fontWeight: "700",
                }}
                onClick={((e) => {
                    window.open('https://investor.tenack.com/signup')
                })}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="tradesman-section">
            <div style={{ height: "110px" }} />

            <div className="card" ref={tradesman}>
              <div className="switch">
                <div className="label">Monthy</div>
                <GreenSwitch
                  value={state.tradesmanMonth}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      tradesmanMonth: !state.tradesmanMonth,
                    }));
                  }}
                />
                <div className="label">Yearly</div>
              </div>

              <div className="title">tradesman</div>

              <div className="amount-div">
                <div className="amount">
                  <div className="symbol">$</div>
                  <div className="number">{state.tradesmanAmount}</div>
                  <div className="save">
                  {state.tradesmanMonth ? "" : "Save 20%"}
                </div>
                </div>
              </div>
              <div className="per-div">
                {state.tradesmanMonth ? "Per Month" : "Per Year"}
              </div>

              <div className="subtitle-features" />

              <div className="list">
                {tradesmanArr.map((item, ind) => (
                  <div className="list-item" key={ind}>
                    <div className="icon">
                      <CheckIcon
                        fontSize="medium"
                        style={{ color: "rgb(18, 100, 100)" }}
                      />
                    </div>
                    <div className="text">{item}</div>
                  </div>
                ))}
              </div>

              <div className="button-div">
                <Button
                  style={{
                    backgroundColor: "rgb(18, 100, 100)",
                    color: "white",
                    fontWeight: "700",
                  }}
                  onClick={((e) => {
                      window.open('https://tradesman.tenack.com/signup')
                  })}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </div>

          <div className="tradesman-section">
            <div style={{ height: "110px" }} />

            <div className="card" ref={investor}>
              <div className="switch">
                <div className="label">Monthy</div>
                <GreenSwitch
                  value={state.investorMonth}
                  onChange={(e) =>
                    setState({
                      ...state,
                      investorMonth: !state.investorMonth,
                    })
                  }
                />
                <div className="label">Yearly</div>
              </div>

              <div className="title">investor</div>
              <div className="amount-div">
                <div className="amount">
                  <div className="symbol">$</div>
                  <div className="number">{state.investorAmount}</div>
                  <div className="save">
                  {state.investorMonth ? "" : "Save 20%"}
                </div>
                </div>
              </div>
              <div className="per-div">
                {state.investorMonth ? "Per Month" : "Per Year"}
              </div>
              <div className="subtitle-features" />

              <div className="list">
                {investorArr.map((item, ind) => (
                  <div className="list-item" key={ind}>
                    <div className="icon">
                      <CheckIcon
                        fontSize="medium"
                        style={{ color: "rgb(18, 100, 100)" }}
                      />
                    </div>
                    <div className="text">{item}</div>
                  </div>
                ))}
              </div>
              <div className="button-div">
                <Button
                  style={{
                    backgroundColor: "rgb(18, 100, 100)",
                    color: "white",
                    fontWeight: "700",
                  }}
                  onClick={((e) => {
                      window.open('https://investor.tenack.com/signup')
                  })}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PricingPage;
