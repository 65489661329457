import React from "react";
import "../Styles/Header.css";
import logo from "../Images/Logos/tenacklogo.png";
import useWindowDimensions from "../Hooks/WindowSize";
import HeaderNavigation from "./HeaderNavigation";
import { navigationPathes } from "./NavigationItems";
import { useNavigate } from "react-router-dom";

function Header() {
  // hooks
  const windowSize = useWindowDimensions();
   const navigate = useNavigate();

  const [state, setState] = React.useState({
    open: false,
  });

  React.useEffect(() => {
    if (windowSize.width > 1200) {
      setState((state) => ({
        ...state,
        open: false,
      }));
      console.log(windowSize.width);
    } else {
      return;
    }
  }, [windowSize.width]);

  return (
    <>
      <div className="Header_background">
        <div className="Header_logo">
          <img
            alt="Tenack Property Maintenace Software Logo"
            src={logo}
            className="Header_logo_img"
            onClick={((e) => {
              navigate(`/`)
            })}
          />
        </div>
        <div className="Header_buttons">
          {windowSize.width < 1200 ? (
            <>
              <div
                className={state.open ? "side-bar-open" : "side-bar" }
                onClick={(e) => {
                  setState((state) => ({
                    ...state,
                    open: !state.open,
                  }));
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>
          ) : (
            <>
               <HeaderNavigation />
            </>
          )}
        </div>
      </div>

      <div className={state.open ? "nav-menu-open" : "nav-menu"}>

      <div className="nav-item"  onClick={((e) => {
              navigate(`/`)
              setState((state) => ({
                ...state, 
                open: false
              }))
            })}>
 <div className="text">{'Home'}</div>
            </div>


<div className="nav-item"  onClick={((e) => {
              navigate(`/login`)
              setState((state) => ({
                ...state, 
                open: false
              }))
            })}>
 <div className="text">{'Login'}</div>
            </div>

        {navigationPathes &&
          navigationPathes.map((item, ind) => (
            <div className="nav-item" key={ind} onClick={((e) => {
              navigate(item.path)
              setState((state) => ({
                ...state, 
                open: false
              }))
            })}>
              <div className="text">{item.title}</div>
            </div>
          ))}
      </div>
    </>
  );
}

export default Header;
