import { Message } from '@mui/icons-material';
import { Card, Fab } from '@mui/material';
import React from 'react';
import "../Styles/Messaging.css"
import MessagesCard from './Messages/MessagesCard';

function Messaging() {

  const [state, setState] = React.useState({
    open: false
  })

  return (
  <>
  <div className='Messaging_Fab'>
    <div style={{position:'relative'}}> 
     <Card className={state.open ? 'messages-card-open' :'messages-card'}> 
     <MessagesCard />
    </Card>
  <Fab button  style={{color:'white', backgroundColor:'rgb(18, 100, 100)', position:'relative'}} onClick={((e) => {
    setState((state) => ({
      ...state, 
      open: !state.open
    }))
  })}>
   
      <Message/>
  </Fab>
  </div>
  </div>
  </>);
}

export default Messaging;
