import { styled } from "@mui/material/styles";
import { Alert, Button, CircularProgress, Snackbar, TextField } from "@mui/material";
import React from "react";
import "../../Styles/Home Page/ContactForm.css";
import MuiPhoneNumber from "material-ui-phone-number-2";
import parsePhoneNumber from "libphonenumber-js";
import { auth, db } from "../../../Firebase";

const TextFieldWhite = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& textarea": {
    color: 'white'

  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const PhoneTextField = styled(MuiPhoneNumber)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& input": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

function ContactForm() {
  const [state, setState] = React.useState({
    email: "",
    first: "",
    last: "",
    phone: "",
    bussiness: "",
    message: "",
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    successMessage: "",
    loading: false
  });

  function handleContactFormSend() {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    auth
      .signInAnonymously()
      .then((r) => {
        db.collection("contact_requests")
          .add({
            email: state.email,
            first_name: state.first,
            last_name: state.last,
            phone: state.phone,
            business: state.bussiness,
            message: state.message,
          })
          .then((r) => {
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successOpen: true,
              successMessage:
                "Successfully sent message... we will be contact soon",
              loading: false,
              email: "",
    first: "",
    last: "",
    phone: "",
    bussiness: "",
    message: "",
            }));
          })
          .catch((r) => {
            setState((state) => ({
              ...state,
              errorOpen: true,
              errorMessage: "Failed to add message...",
              successOpen: false,
              successMessage: "",
              loading: false,
            }));
          });
      })
      .catch((r) => {
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to add message...",
          successOpen: false,
          successMessage: "",
          loading: false,
        }));
      });
  }

  function handlePhoneChange(e) {
    const phone = parsePhoneNumber(e);
    if (phone) {
      setState((state) => ({
        ...state,
        phone: phone.number,
      }));
    }

    console.log(phone);
  }



  const [vertical, ] = React.useState("bottom");

  const [horizontal, ] = React.useState("right");



  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      errorMessage: "",
      successMessage: '', 
      successOpen: ''
    }));
  };

  return (
    //<div className='ContactForm'>
    <div className="ContactForm_form">


<Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{maxWidth:'30%'}}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{maxWidth:'30%'}}
      >
        <Alert onClose={handleErrorClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>



      {state.loading &&
      <div className="message_loading"> 
      <div className="message_child"> 
      <CircularProgress style={{color:'white'}}/>
      </div>
      </div>
      }


      <div className="fullwidth_field">
        <TextFieldWhite
          fullWidth
          label="Email Address"
          placeholder="support@tenack.com"
          type={"email"}
          style={{ margin: "10px", color: "white" }}
          value={state.email}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              email: e.target.value,
            }));
          }}
        />
      </div>

      <div className="ContactForm_form_half_div">
        <div className="halfwidth_field">
          <TextFieldWhite
            fullWidth
            label="First Name"
            placeholder="John"
            style={{ margin: "10px", color: "white" }}
            value={state.first}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                first: e.target.value,
              }));
            }}
          />
        </div>

        <div className="halfwidth_field">
          <TextFieldWhite
            fullWidth
            label="Last Name"
            placeholder="Smith"
            type={"email"}
            style={{ margin: "10px", color: "white" }}
            value={state.last}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                last: e.target.value,
              }));
            }}
          />
        </div>
      </div>
      <div className="ContactForm_form_half_div">
        <div className="halfwidth_field">
          <PhoneTextField
            value={state.phone}
            onChange={(e) => {
              handlePhoneChange(e);
            }}
            fullWidth
            style={{ margin: "10px", color: "white" }}
            variant="outlined"
            defaultCountry="au"
            label="Phone"
          />
        </div>
        <div className="halfwidth_field">
          <TextFieldWhite
            fullWidth
            label="Organisation Name"
            placeholder="Tenack"
            style={{ margin: "10px", color: "white" }}
            value={state.bussiness}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                bussiness: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="fullwidth_field">
        <TextFieldWhite
          fullWidth
          label="Message"
          type={"email"}
          style={{ margin: "10px", color: "white" }}
          multiline
          rows={7}
          value={state.message}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              message: e.target.value,
            }));
          }}
        />
      </div>
      <div className="ContactForm_form_button_bar">
        <Button
          style={{ color: "rgb(18, 100, 100)", backgroundColor: "white" }}
          onClick={((e) => {
            handleContactFormSend()
          })}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ContactForm;
