import React from "react";
import "./StillGotAQuestionCard.css";
import funkyarrow from '../../Images/Photos/arrow.png'

function StillGotAQuestionCard() {
  return (
    <div className="StillGotAQuestionCard">
      <div className="message">
        <div className="title">got a question?</div>
        <div className="comment">
          We get it, sometimes you want the answer right away that's why we're
          here to chat, send us a message using our chat function to start a
          text message conversation with our support staff.
        </div>
      </div>

      <img
        src={funkyarrow}
        alt="Tenack Property maintenace funky arrow"
        className="arrow"
      />
    </div>
  );
}

export default StillGotAQuestionCard;
