import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import HomePage from './Components/Home Page/HomePage';
import Header from './Components/Header/Header';
import Messaging from './Components/Messaging/Messaging';
import LoginPage from './Components/Login Page/LoginPage';
import TradesmanPage from './Components/Tradesman Page/TradesmanPage';
import InvestorPage from './Components/Investor Page/InvestorPage';
import TenantPage from './Components/Tenant Page/TenantPage';
import EveryonePage from './Components/Everyone Page/EveryonePage';
import AboutUsPage from './Components/About Us/AboutUsPage';
import PricingPage from './Components/Pricing Page/PricingPage';


function App() {
  return (
    <BrowserRouter>
    <Header />
    <Messaging />
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/tradesman' element={<TradesmanPage />} />
      <Route path='/investor' element={<InvestorPage />} />
      <Route path='/tenant' element={<TenantPage />} />
      <Route path='/everyone' element={<EveryonePage />} />
      <Route path='/about-us' element={<AboutUsPage />} />
      <Route path='/pricing' element={<PricingPage />} />
    </Routes> 
    </BrowserRouter>
   

  );
}

export default App;
