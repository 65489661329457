import React from "react";
import "../../Styles/Home Page/EveryoneCard.css";
import assetPhoto from "../../Images/Photos/Tenack Asset Details.png";
import { Button } from "@mui/material";
import useOnScreen from "../../Hooks/OnScreen";
import { useNavigate } from "react-router-dom";

function EveryoneCard() {
  const navigate = useNavigate();

  const ref = React.useRef();
  const onScreen = useOnScreen(ref, "0px");
  const [opacity, setOpacity] = React.useState("EveryoneCard_img");

  React.useEffect(() => {
    if (onScreen) {
      setOpacity("EveryoneCard_img");
    } else {
      setOpacity("EveryoneCard_img_out");
    }
    console.log(onScreen);
  }, [onScreen]);

  return (
    <div className="EveryoneCard" id="EveryoneCard">
      <div className="EveryoneCard_left">
        <div>
          <img alt="" src={assetPhoto} className={opacity} ref={ref} />
        </div>
      </div>
      <div className="EveryoneCard_right">
        <div className="Investor_Card_title">Made For Everyone...</div>

        <div className="InvestorCard_sub_text">
          Tenack is a platform that allows all interested parties to interact
          and access their properties differently, enabling a unique experience
          tailored to their needs. This can help tradesmen, tenants and property
          owners save time by dealing with admin more efficiently.
        </div>

        <div>
          <Button
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              navigate("/everyone");
            }}
          >
            Learn More...
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EveryoneCard;
