import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import tapImage from "../../Images/Photos/tap_image.png";
import "../../Styles/Home Page/TradesmanCard.css"

function TradesmanCard() {

  const navigate = useNavigate();
  return (
    <div className="TradesmanCard" id='TradesmanCard'>
      <div className="TradesmanCard_img_half">
        <img
          src={tapImage}
          alt="Brass Outdoor tap, plumbing and repair example"
          className="TradesmanCard_img"
        />
      </div>
      <div className="TradesmanCard_text_half"> 
          <div className="TradesmanCard_title">Made For Tradesman...</div>
      <div className="TradesmanCard_sub_text"> 
      When you're a tradesman, your time is valuable. You need a software
          solution that lets you get to work and interact with your clients'
          properties like never before. Tenack is that solution. Tenack is a
          software solution with access designed for tradesman, allowing
          tradesman to access and interact with client properties like never
          before.      </div>
      <div>
      <Button style={{color:'rgb(18, 100, 100)'}} onClick={((e) => {
        navigate('/tradesman')
      })}>
          Learn More...
      </Button>
      </div>
      </div>
    </div>
  );
}

export default TradesmanCard;
