import React from "react";
import logo from "../../Images/Logos/tenacklogo.png";
import "../../Styles/Home Page/TenackLogoCard.css";
import { useRef } from "react";
import useWindowDimensions from "../../Hooks/WindowSize";

function TenackLogoCard() {
  /* hooks */
  const window = useWindowDimensions();

  /* state variables */

  const [active, setActive] = React.useState(1);

  React.useEffect(() => {
    let ind = 1;
    const interval = setInterval(() => {
      if (ind === 1 || ind === 2 || ind === 3) {
        setActive(ind + 1);
        ind = ind + 1;
      } else if (ind == 4) {
        ind = 1;

        setActive(1);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="LogoSlide">
        <div className="LogoSlide_image_side">
        </div>
        <div className="LogoSlide_text_side">
          <div className="LogoSlide_logo_line">
            <img
              alt="Tenack Property Maintenace Logo"
              src={logo}
              className="LogoSlide_logo"
            />
          </div>
          <div className="LogoSlide_sub_text_line">
            Property And Asset Managment Software
          </div>
          <div className="LogoSlide_text_line">
            <div className="LogoSlide_text_for">Made For</div>
            <div className="LogoSlide_text_for_results">
              <span className={active === 1 ? "active" : "hidden"}>
                Property Investors...
              </span>
              <span className={active === 2 ? "active" : "hidden"}>
                Tradesman...
              </span>
              <span className={active === 3 ? "active" : "hidden"}>
                Property Managers...
              </span>
              <span className={active === 4 ? "active" : "hidden"}>
                Tenants...
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TenackLogoCard;
