import { Button } from "@mui/material";
import React from "react";
import LogoCard from "./LogoCard";
import MobileContactCard from "./MobileContactCard";
import "./MobileHomePage.css";
import StillGotAQuestionCard from "./StillGotAQuestionCard";
import { useNavigate } from "react-router-dom";
import tap from "../../Images/Photos/tap_image.png";
import InvestorIncrease from "./Mobile Parts/InvestorIncrease";
import TenantShake from "./Mobile Parts/TenantShake";
import form from "../../Images/Photos/Tenack Asset Details.png";

function MobileHomePage() {
  const navigate = useNavigate();

  const cards = [
    {
      id: Math.random() * 10,
      bio: ` When you're a tradesman, your time is valuable. You need a software
      solution that lets you get to work and interact with your clients'
      properties like never before. Tenack is that solution. Tenack is a
      software solution with access designed for tradesman, allowing
      tradesman to access and interact with client properties like never
      before.`,
      title: "Made for tradesman.",
      path: "/tradesman",
      extra: (
        <img
          src={tap}
          style={{
            height: "250px",
            transform: "scaleX(-1)",
            position: "absolute",
            right: "0px",
          }}
        />
      ),
    },
    {
      id: Math.random() * 10,
      bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
    condimentum sodales porttitor. Quisque et nunc sed ipsum pulvinar
    interdum. Phasellus sed ultricies felis. Sed vitae scelerisque nisi.
    Etiam egestas tincidunt consequat. Maecenas arcu tellus, commodo at
    ullamcorper eu, elementum quis urna. Etiam ac dictum lacus, et sodales
    felis`,
      title: "Made for investors.",
      path: "/investor",
      extra: <InvestorIncrease />,
    },
    {
      id: "contact",
      element: <StillGotAQuestionCard />,
    },
    {
      id: Math.random() * 10,
      bio: ` Tenants, we know you're busy. We also know that you want to keep an
      eye on what's going on with your home. With Tenack, you can
      see the status of maintenance requests at a glance and get updates on
      progress. You'll never in the dark about what's happening with your
      property even again.`,
      title: "Made for tenants.",
      path: "/tenant",
      extra: <TenantShake />,
    },
    {
      id: Math.random() * 10,
      bio: `Tenack is a platform that allows all interested parties to interact and access their properties differently, enabling a unique experience tailored to their needs. This can help tradesmen, tenants and property owners save time by dealing with admin more efficiently.`,
      title: "Made for everyone.",
      path: "/everyone",
      extra: (
        <img
          src={form}
          style={{
            position: "absolute",
            right: "0px",
            width: "70vw",
            maxWidth: "400px",
          }}
        />
      ),
    },
  ];
  return (
    <div className="MobileHomePage">
      <div className="list">
        <div className="list-item">
          <LogoCard />
        </div>
        {cards &&
          cards.map((item, ind) => {
            if (typeof item.element !== "undefined") {
              return (
                <div className="list-item" key={item.id}>
                  {item.element}
                </div>
              );
            } else {
              return (
                <div className="list-item" key={item.id}>
                  <div className="content">
                    <div className="title">{item.title}</div>
                    <div className="message">{item.bio}</div>
                    <Button
                      style={{
                        marginTop: "10px",
                        color: "rgb(18, 100, 100)",
                      }}
                      onClick={(e) => {
                        navigate(item.path);
                      }}
                    >
                      Learn More...
                    </Button>
                  </div>
                  <div className="extra">
                    <div className="image">{item.extra && item.extra}</div>
                  </div>
                </div>
              );
            }
          })}
        <div className="list-item">
          <MobileContactCard />
        </div>
      </div>

      {/* tradesman */}
      {/* investor */}
      {/* tenant */}
      {/* everyone */}

      {/* goals */}
    </div>
  );
}

export default MobileHomePage;
