import { Button } from "@mui/material";
import React, { cloneElement } from "react";
import { useRef } from "react";
import AnimatedNumber from "react-animated-number/build/AnimatedNumber";
import { useNavigate } from "react-router-dom";
import useOnScreen from "../../Hooks/OnScreen";
import "../../Styles/Home Page/InvestorCard.css";
import AmountElement from "./AmountElement";

function InvestorCard(props) {
  const [amount, setAmount] = React.useState(0);

  const navigate = useNavigate();

  const ref = React.useRef();
  const onScreen = useOnScreen(ref, "0px");

  React.useEffect(() => {
    if (onScreen) {
      setAmount(10535.36);
    } else {
      setAmount(3000);
    }
    console.log(onScreen);
  }, [onScreen]);

  return (
    <div className="InvestorCard" id="InvestorCard">
      <div className="InvestorCard_img_half" ref={ref}>
        <div className="InvestorCard_text_lines">
          <div className="InvestorCard_cost_line">
            <AnimatedNumber
              value={amount}
              style={{ fontSize: "60pt" }}
              formatValue={(v) => `$${v.toFixed(2)}`}
              duration={4000}
              frameStyle={(perc) => ({ opacity: perc / 100 })}
            />
          </div>
          <div className="InvestorCard_cost_title_line">Total Expenses</div>
        </div>
      </div>
      <div className="InvestorCard_text_half">
        <div className="Investor_Card_title">
          Made For Property Investors...
        </div>

        <div className="InvestorCard_sub_text">
          Tenack is the best way to keep track of all your properties. Whether
          you've got one or fifty, we can help you keep everything in order.
          With Tenack, you'll be able to manage your warranty claims and
          servicing requests, as well as maintenance requests—all in one place.
          With the click of a button, you can track each individual property's
          status and receive notifications when anything needs attention.
        </div>

        <div>
          <Button
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              navigate("/investor");
            }}
          >
            Learn More...
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InvestorCard;
