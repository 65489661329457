import React from "react";
import "./Styles/AboutUsPage.css";
import useOnScreen from "../Hooks/OnScreen";
import tenackausmap from './Photos/tenack-aus-map.svg' 
import helpful from './Photos/helpful.svg' 
import vision from './Photos/tenack-vision-image.svg' 
import mission from './Photos/mission.svg' 
import why from './Photos/why.svg' 
import { Avatar} from "@mui/material"
import { Helmet } from 'react-helmet';


function AboutUsPage() {
  const ref01 = React.useRef();
  const ref02 = React.useRef();
  const ref03 = React.useRef();
  const ref04 = React.useRef();
  const ref05 = React.useRef();

  const onScreen01 = useOnScreen(ref01, "-100px");
  const [style01, setStyle01] = React.useState("out");
  const onScreen02 = useOnScreen(ref02, "-100px");
  const [style02, setStyle02] = React.useState("out");
  const onScreen03 = useOnScreen(ref03, "-100px");
  const [style03, setStyle03] = React.useState("out");
  const onScreen04 = useOnScreen(ref04, "-100px");
  const [style04, setStyle04] = React.useState("out");
  const onScreen05 = useOnScreen(ref05, "-100px");
  const [style05, setStyle05] = React.useState("out");

  React.useEffect(() => {
    if (onScreen01) {
      setStyle01("in");
    } else {
      setStyle01("out");
    }
  }, [onScreen01]);

  React.useEffect(() => {
    if (onScreen02) {
      setStyle02("in");
    } else {
      setStyle02("out");
    }
  }, [onScreen02]);

  React.useEffect(() => {
    if (onScreen03) {
      setStyle03("in");
    } else {
      setStyle03("out");
    }
  }, [onScreen03]);

  React.useEffect(() => {
    if (onScreen04) {
      setStyle04("in");
    } else {
      setStyle04("out");
    }
  }, [onScreen04]);

  React.useEffect(() => {
    if (onScreen05) {
      setStyle05("in");
    } else {
      setStyle05("out");
    }
  }, [onScreen05]);

  const AboutUsPage = [
    {
      number: `01`,
      title: "Made In Australia.",
      message: `Tenack is a property maintenance app made in central Victoria, Australia. Designed to make the lives of property managers, owners and tradesman easier, Tenack gives you access to an easy-to-use platform that works from any device with a web browser.`,
      image: tenackausmap,
      id: ref01,
      style: style01,
    },
    {
      number: `02`,
      title: "Made To Help.",
      message: `With Tenack, you can manage your properties and residential property assets in an easy, effective way. Tenack streamlines all aspects of managing your real estate needs. From property management, to maintenance request tracking and more, Tenack will help you run your business and life in a more efficient manner so that you can focus on the important things.
      `,
      image: helpful,
      id: ref02,
      style: style02,
    },
    {
      number: `03`,
      title: "Vision.",
      message: `At Tenack, our vision is to provide a flawless and intuitive solution that allows our users to experience complete control when maintaining assets within both their personal and investment properties.

      By offering a user-friendly interface, we allow users to maintain the value of their investments for years to come.
      `,
      image: vision,
      id: ref03,
      style: style03,
    },
    {
      number: `04`,
      title: "Mission.",
      message: `We know you have a lot to do. We want to help you get it done faster, easier, and more efficiently.

      At Tenack, we believe that the best way to do that is by providing high-quality software and technology solutions that increase the efficiency and communication between relevant stakeholders predominantly within the maintenance and construction industries.
      
      
      `,
      image: mission,
      id: ref04,
      style: style04,
    },
    {
      number: `05`,
      title: "Why.",
      message: `We thought we could do better.

      Declan and Tim have been involved in property maintenance for a long time, and they've seen it all: wasted time, wasted money, and unnecessary admin. They knew there had to be a better way to manage your property maintenance—one that didn't just benefit one party (like many other apps) and that actually made a difference in the world of property maintenance.
      
      So we started developing Tenack with the goal of helping tradespeople and home owners like you avoid unnecessary admin.
      `,
      image: why,
      id: ref05,
      style: style05,
    },
  ];

  return (
    <>

  

<Helmet>
                <meta charSet="utf-8" name="Tenack Property Maintenance | About Us" content="Tenack is a property maintenance app that makes your life a little easier. It was created by people in the trades industry with a focus on solving a real-world need through customer suggestions and ideas along the way. Tenack was designed to take care of your day to day property issues with practical, easy to use features that makes timely invoicing and task tracking simple for any size business." />
                <title>Tenack | About Us</title>
                <link rel="canonical" href="http://tenack.com/about-us" />
            </Helmet>

    
      <div style={{ height: "50px" }} />

      <div className="AboutUsPage">
        <div className="list">
          {AboutUsPage &&
            AboutUsPage.map((item, ind) => (
              <div className="listitem" key={ind}>
                <div className="number">{`${item.number}.`}</div>
                <h1 className="title">
                  {item.title} 
                  </h1>
                  <p className="message">{item.message}</p>

                  <div className="image">
                    <img
                      src={item.image}
                      alt={item.title}
                      className={item.style}
                      ref={item.id}
                    />
                 
                </div>
              </div>
            ))}

          <div className="listitembottom">
            <div className="number">{`06.`}</div>
            <div className="title">
              {"Meet the team."}
              <div className="team">
                <div className="member">
                  <div className="membertitle">
                    <Avatar
                      className="avatar"
                      style={{ height: "70px", width: "70px" }}
                    />
                    <div className="nametitle">Declan Miller</div>
                  </div>
                  <div className="bio">
                  Declan is our software engineer. He's in charge of making and developing everything app-related—including the Tenack platform itself. His goal is to develop Tenack using feedback and suggestions from customers to build a platform that actually solves a real need. When he's not at the computer working on Tenack, Declan enjoys gardening and making wooden furniture in his wood shop.

                  </div>
                </div>
                <div className="member">
                  <div className="membertitle">
                    <Avatar
                      className="avatar"
                      style={{ height: "70px", width: "70px" }}
                    />
                    <div className="nametitle">Tim Arnold</div>
                  </div>
                  <div className="bio">
                  Tim Has spent his career as an electrician, running all aspects of his own local electrical company. He has built two houses owner builder so far, and like many other people in the trades industry have tackled many at home projects and repairs himself bringing a wealth of knowledge regarding property maintenance and construction.

Tim passionate about the electrical and trades industry and sees Tenack as an opportunity to help others by providing a solution that promotes quality service at an affordable price.

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsPage;
