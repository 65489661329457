import { Notifications } from '@mui/icons-material';
import { Alert, Badge } from '@mui/material';
import React from 'react'
import { ShakeRotate } from 'reshake';
import useOnScreen from '../../../Hooks/OnScreen';

function TenantShake() {

    const ref = React.useRef();
    const onScreen = useOnScreen(ref, "0px");
    const [invisable, setInvisable] = React.useState(true);
    const [shaking, setShaking] = React.useState(true);
    const [alertVisable, setAlertVisable] = React.useState(false);
  
    React.useEffect(() => {
      if (onScreen) {
        setTimeout(() => {
          setInvisable(false);
          setShaking(true);
        }, 1500);
        setTimeout(() => {
          setShaking(false);
        }, 2500);
        setTimeout(() => {
          setAlertVisable(true);
        }, 500);
      } else {
        setInvisable(true);
        setShaking(false);
        setAlertVisable(false);
      }
      console.log(onScreen);
    }, [onScreen]);


  return (
    <div style={{
        width:'300px', 
        position:'absolute', 
        height:'40px', 
        top:'0px', 
        bottom:'0px', 
        margin:'auto', 
        marginLeft:'10px'
    }} ref={ref}>
          <Badge
            badgeContent={
              <ShakeRotate active={shaking} fixed={true}>
                <Notifications style={{ color: "rgb(18, 100, 100)" }} />
              </ShakeRotate>
            }
            invisible={invisable}
          >
            {alertVisable && (
              <Alert severity="success" style={{ width: "100%" }}>
                Your Tradesman Is 5 Minutes Away
              </Alert>
            )}
          </Badge>
        </div>
  )
}

export default TenantShake