import firebase from "firebase";
import "firebase/auth";
import 'firebase/firestore';
import 'firebase/storage'

const app = firebase.initializeApp({
    apiKey: "AIzaSyDJ4Y241KaY_gPr4Q_bLUzTxmRUJP7Y4Sk",
    apiKey: "AIzaSyAe3N0lMsVHU2pZzenobw_48GFDRDi0Ef0",
    authDomain: "tenack-website.firebaseapp.com",
    projectId: "tenack-website",
    storageBucket: "tenack-website.appspot.com",
    messagingSenderId: "525121285949",
    appId: "1:525121285949:web:b8eeff0fbba641ed275f54",
    measurementId: "G-YLLL1NBCTD"
})
export const auth = app.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

export default app 
