import React from 'react'
import AnimatedNumber from 'react-animated-number/build/AnimatedNumber';
import useOnScreen from '../../../Hooks/OnScreen';

function InvestorIncrease() {

    
    const [amount, setAmount] = React.useState(0);
    const ref = React.useRef();
  const onScreen = useOnScreen(ref, "0px");

  React.useEffect(() => {
    if (onScreen) {
      setAmount(10535.36);
    } else {
      setAmount(3000);
    }
    console.log(onScreen);
  }, [onScreen]);


  return (
      <div ref={ref} style={{
          position:'absolute', 
          top: '0', 
          bottom: '0', 
          margin:'auto',
          height:'100px', 
          fontWeight:'600'
      }}> 
            <AnimatedNumber
              value={amount}
              style={{ fontSize: "40pt" }}
              formatValue={(v) => `$${v.toFixed(2)}`}
              duration={4000}
              frameStyle={(perc) => ({ opacity: perc / 100 })}
            />  
      </div>

  )
}

export default InvestorIncrease