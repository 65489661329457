import React from "react";
import "../../Styles/Home Page/ContactCard.css";
import ContactForm from "./ContactForm";
import arrow from "../../Images/Photos/arrow.png";

function ContactCard() {
  return (
    <div className="ContactCard">
      <div className="ContactCard_form_side">
        <ContactForm />
      </div>
      <div className="ContactCard_text_side">
        <div className="ContactCard_text_div">
          <div className="ContactCard_text_title">Still Got A Question?</div>
          <div className="ContactCard_text_sub_text">
            If you'd like to find out more infomation about Tenack and how
            the platform works or if your an existing customer and still have 
            a few questions or even suggestions for new features, send us a 
            request, and we'll get back to you as soon as we can. 
          </div>
        </div>
        <div className="ContactCard_image_div">
          <div className="ContactCard_image_div_sub">
              <div className="ContactCard_fab_comments"> 
                 <div>Rather send us a text? </div> 
                 <div>start a text chat now </div> 
              </div> 

            <img src={arrow} className="ContactCard_img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
