import { Send } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import { auth, db } from "../../../Firebase";
import "./Styles/MessagesCard.css";
import MuiPhoneNumber from "material-ui-phone-number-2";
import parsePhoneNumber from "libphonenumber-js";
import firebase from "firebase";

function MessagesCard() {
  const styles = (theme) => ({
    multilineColor: {
      color: "red",
    },
  });

  const [state, setState] = React.useState({
    phone: "",
    name: "",
    message: "",
    loading: false,
    new: true,
    messages: [
      {
        sender: "system",
        message: `Hi, Thanks for showing an interest in Tenack, please enter your
        infomation and our team will text you back shortly on the provided
        number.`,
      },
    ],
  });

  function handleMessageSend() {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    auth
      .signInAnonymously()
      .then((r) => {
        db.collection("website_chats")
          .doc(state.phone)
          .set({
            name: state.name,
            phone: state.phone,
          })
          .then((e) => {
            db.collection("website_chats")
              .doc(state.phone)
              .collection("messages")
              .add({
                message: state.message,
                created_at: firebase.firestore.FieldValue.serverTimestamp()
              });
          })
          .then((x) => {
            setState((state) => ({
              ...state,
              loading: false,
              messages: [
                ...state.messages,
                {
                  sender: r.user.uid,
                  message: state.message,
                },
              ],
              new: false,
              message: "",
            }));
          })
          .catch((r) => {
            setState((state) => ({
              ...state,
              errorMessage: "Failed to send message...",
              errorOpen: true,
              loading: false,
            }));
          });
      })
      .catch((r) => {
        console.log("error", r);
      });
  }

  React.useEffect(() => {
    if (!state.new) {
      setTimeout(function () {
        setState((state) => ({
          ...state,
          messages: [
            ...state.messages,
            {
              sender: "system",
              message: `Hi ${state.name}, thanks for sending us a message our
               team will get in contact with you as soon as possible on ${state.phone},
              you should receive a confirmation message shortly.`,
            },
          ],
        }));
      }, 500);
    } else {
      return;
    }
  }, [state.new]);

  function Messages() {
    return (
      state.messages &&
      state.messages.map((item, ind) => {
        if (item.sender === "system") {
          return (
            <div className="default-messages-master" key={ind}>
              <div className="default-message">
                <div className="text">{item.message}</div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="send-messages-master" key={ind}>
              <div className="send-message">
                <div className="text">{item.message}</div>
              </div>
            </div>
          );
        }
      })
    );
  }

  React.useEffect(() => {
    console.log(state.messages);
  }, [state.messages]);

  function handlePhoneChange(e) {
    const phone = parsePhoneNumber(e);
    if (phone) {
      setState((state) => ({
        ...state,
        phone: phone.number,
      }));
    }

    console.log(phone);
  }

  return (
    <div className="MessagesCard">
      {state.loading && (
        <div className="loading">
          <div className="sub">
            <CircularProgress />
          </div>
        </div>
      )}
      <div className="messages">
        <Messages />

        {state.new && (
          <div className="send-messages-master">
            <div className="send-message">
              <div className="input">
                <TextField
                  fullWidth
                  label="Name"
                  placeholder="John Smith..."
                  size="small"
                  value={state.name}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="input">
                <MuiPhoneNumber
                  value={state.phone}
                  onChange={(e) => {
                    handlePhoneChange(e);
                  }}
                  fullWidth
                  variant="outlined"
                  defaultCountry="au"
                  size="small"
                />
              </div>
              <div className="input">
                <TextField
                  maxRows={4}
                  multiline
                  fullWidth
                  minRows={2}
                  label="Message"
                  placeholder="Message..."
                  size="small"
                  value={state.message}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      message: e.target.value,
                    }));
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => {
                            handleMessageSend();
                          }}
                        >
                          <Send style={{ color: "rgb(18, 100, 100)" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessagesCard;
