import { Button } from "@mui/material";
import React from "react";
import "./Styles/LoginPage.css";
import { Helmet } from 'react-helmet';


function LoginPage() {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Tenack | Login</title>
                <link rel="canonical" href="http://tenack.com/login" />
            </Helmet>

    
      <div className="LoginPage_content">
        <div className="LoginPage_title">Which Deparment can i put you through to today?</div>

          <div
          className="LoginPage_button"
          onClick={((e) => {
              window.open('https://investor.tenack.com')
          })}
          >Property Investor
          </div>
        <div className="LoginPage_button"
        onClick={((e) => {
            window.open('investor.tenack.com')
        })}>
          Tradesman Office
        </div>

        <div
          className="LoginPage_button"
          onClick={((e) => {
            window.open('https://field.tenack.com')
        })}
          >Tradesman Field
        </div>
      </div>
    </>
  );
}

export default LoginPage;
