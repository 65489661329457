import AboutUsCard from "./AboutUsCard";
import ContactCard from "./ContactCard";
import EveryoneCard from "./EveryoneCard";
import InvestorCard from "./InvestorCard";
import StartingCard from "./StartingCard";
import TenackLogoCard from "./TenackLogoCard";
import TenantCard from "./TenantCard";
import TradesmanCard from "./TradesmanCard";

export const HomePageCardList = [
    {
        component: <TenackLogoCard/>,
    },
    {
        component: <TradesmanCard color={'purple'} index={1}/>,
        testing: 'Card Two' 
    },
    {
        component: <InvestorCard color={'blue'} index={2}/>,
        testing: 'Card Three' 
    },
    {
        component: <TenantCard color={'green'} index={3}/>,
        testing: 'Card Four' 
    },
    {
        component: <EveryoneCard color={'yellow'} index={4}/>,
        testing: 'Card Five' 
    },
    {
        component: <AboutUsCard color={'orange'} index={5}/>,
        testing: 'Card Six' 
    },
    {
        component: <ContactCard color={'pink'} index={6}/>,
        testing: 'Card Seven' 
    }, 
    
]