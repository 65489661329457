import React from "react";
import "./Styles/TradesmanPage.css";
import useOnScreen from "../Hooks/OnScreen";
import increaseMA from "./Photos/increaseMA.svg";
import reduce from "./Photos/reduce.svg";
import reduceQ from "./Photos/reduceQ.svg";
import retain from "./Photos/retain.svg";
import standout from "./Photos/standout.svg";
import { Helmet } from 'react-helmet';


function TradesmanPage() {
  const ref01 = React.useRef();
  const ref02 = React.useRef();
  const ref03 = React.useRef();
  const ref04 = React.useRef();
  const ref05 = React.useRef();

  const onScreen01 = useOnScreen(ref01, "-100px");
  const [style01, setStyle01] = React.useState("out");
  const onScreen02 = useOnScreen(ref02, "-100px");
  const [style02, setStyle02] = React.useState("out");
  const onScreen03 = useOnScreen(ref03, "-100px");
  const [style03, setStyle03] = React.useState("out");
  const onScreen04 = useOnScreen(ref04, "-100px");
  const [style04, setStyle04] = React.useState("out");
  const onScreen05 = useOnScreen(ref05, "-100px");
  const [style05, setStyle05] = React.useState("out");

  React.useEffect(() => {
    if (onScreen01) {
      setStyle01("in");
    } else {
      setStyle01("out");
    }
  }, [onScreen01]);

  React.useEffect(() => {
    if (onScreen02) {
      setStyle02("in");
    } else {
      setStyle02("out");
    }
  }, [onScreen02]);

  React.useEffect(() => {
    if (onScreen03) {
      setStyle03("in");
    } else {
      setStyle03("out");
    }
  }, [onScreen03]);

  React.useEffect(() => {
    if (onScreen04) {
      setStyle04("in");
    } else {
      setStyle04("out");
    }
  }, [onScreen04]);

  React.useEffect(() => {
    if (onScreen05) {
      setStyle05("in");
    } else {
      setStyle05("out");
    }
  }, [onScreen05]);

  const TradesmanPageItems = [
    {
      number: `01`,
      title: "Retain your most valuable customers.",
      message: `Tenack is a new way of doing business in the trades industry.

       We believe that by promoting quality tradesman and providing a digial platform like no other, we will be able create a quality relationship between property owners and tradesman like never before. By allowing select tradesman to access our clients' digital properties, a unique relationship between tradesman and investors can be made.`,
      image: retain,
      id: ref01,
      style: style01,
    },
    {
      number: `02`,
      title: "Reduce unpaid callouts.",
      message: `You know that feeling when you open the door and your gut sinks? You're about to go on a site visit, and you're not sure what you're going to find.

      With Tenack, all of your fears will be put to rest. Tenack allows tradesman to have access to all the property details before you even get a maintenance request—you can provide more accurate quotes and estimates without having to make a site visit.`,
      image: reduce,
      id: ref02,
      style: style02,
    },
    {
      number: `03`,
      title: "Reduce qoute turnaround time.",
      message: `Tenack is a tool that helps you reduce quote and estimate turnaround time by reducing the amount of hunting around for part numbers and warranty documentation.

      It keeps all your estimates in one place, so you can focus on what matters: selling your services. Tenack removes the need to question what the tradesman before you did or didn't do, because it's all right there in front of you.`,
      image: reduceQ,
      id: ref03,
      style: style03,
    },
    {
      number: `04`,
      title: "Increased maintenace accountability.",
      message: `Going to a new client's property can be scary. You don't know whether the last tradesperson to visit has done everything correctly, and you're worried that your work will undo their work or vice versa.

      With Tenack, you'll never have to worry about that again. We know who has been at a property before you, and what they've done—and we'll tell you! We'll also tell you if they've left anything undone.
      
      Tenack is dedicated to increasing accountability in the trades industry. Our goal is to give customers peace of mind when it comes to maintaining their assets.`,
      image: increaseMA,
      id: ref04,
      style: style04,
    },
    {
      number: `05`,
      title: "Stand out from your competitors.",
      message: `Tenack is a platform that helps tradesmen stand out from their competitors.

      Every business needs a unique selling proposition, and Tenack can help you find yours by showing customers you're invested in the future of property maintenance and willing to move with it. That's how you can build a loyal customer base.`,
      image: standout,
      id: ref05,
      style: style05,
    },
  ];

  return (
    <>
    <Helmet>
                <meta charSet="utf-8" name="Tenack Property Maintenace Software | Made for Tradesman" content="Tenack is made for tradesmen who want to get the job done. With Tenack you can reach your customers wherever they are, and make sure all warranty documents reach them quickly and safely." />
                <title>Tenack | Tradesman</title>
                <link rel="canonical" href="http://tenack.com/trademsan" />
            </Helmet>

      <div style={{ height: "50px" }} />

      <div className="TradesmanPage">
        <div className="list">
          {TradesmanPageItems &&
            TradesmanPageItems.map((item, ind) => (
              <div className="listitem" key={ind}>
                <div className="number">{`${item.number}.`}</div>
                <h1 className="title">
                  {item.title} 
                  </h1>
                  <p className="message">{item.message}</p>

                  <div className="image">
                    <img
                      src={item.image}
                      alt={item.title}
                      className={item.style}
                      ref={item.id}
                    />
                 
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default TradesmanPage;
