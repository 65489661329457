import { Notifications } from "@mui/icons-material";
import { Alert, Badge, Button, Snackbar } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ShakeRotate } from "reshake";
import useOnScreen from "../../Hooks/OnScreen";
import "../../Styles/Home Page/TenantCard.css";

function TenantCard() {
  const ref = React.useRef();
  const onScreen = useOnScreen(ref, "0px");
  const [invisable, setInvisable] = React.useState(true);
  const [shaking, setShaking] = React.useState(true);
  const [alertVisable, setAlertVisable] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (onScreen) {
      setTimeout(() => {
        setInvisable(false);
        setShaking(true);
      }, 1500);
      setTimeout(() => {
        setShaking(false);
      }, 2500);
      setTimeout(() => {
        setAlertVisable(true);
      }, 500);
    } else {
      setInvisable(true);
      setShaking(false);
      setAlertVisable(false);
    }
    console.log(onScreen);
  }, [onScreen]);

  return (
    <div className="TenantCard" id='TenantCard'>
      <div className="TenantCard_left">
        <div className="TenantCard_notifaction" ref={ref}>
          <Badge
            badgeContent={
              <ShakeRotate active={shaking} fixed={true}>
                <Notifications style={{ color: "rgb(18, 100, 100)" }} />
              </ShakeRotate>
            }
            invisible={invisable}
          >
            {alertVisable && (
              <Alert severity="success" style={{ width: "100%" }}>
                {" "}
                Your Tradesman Is 5 Minutes Away{" "}
              </Alert>
            )}
          </Badge>
        </div>
      </div>
      <div className="TenantCard_right">
        <div className="Investor_Card_title">
          Made For Tenants...
        </div>

        <div className="InvestorCard_sub_text">
        Tenants, we know you're busy. We also know that you want to keep an
          eye on what's going on with your home. With Tenack, you can
          see the status of maintenance requests at a glance and get updates on
          progress. You'll never in the dark about what's happening with your
          property even again.
        </div>

        <div>
          <Button style={{ color: "rgb(18, 100, 100)" }} onClick={((e) => {
            navigate('/tenant')
          })}>Learn More...</Button>
        </div>
      </div>
    </div>
  );
}

export default TenantCard;
