import React from "react";
import ContactForm from "../Home Page Cards/ContactForm";
import "./Styles/ContactCardMobile.css";
import funkyArrow from "../../Images/Photos/arrow.png"

function MobileContactCard() {
  return (
    <>
    
      <div className="MobileContactCard_content">
      <div className="MobileContactCard_title"> 
    Contact Us.
    </div>
        <div className="MobileContactCard_cotent">
          <div className="MobileContactForm">
            <ContactForm />
          </div>
        </div>

    
      </div>
    </>
  );
}

export default MobileContactCard;
