import React from "react";
import "./Styles/InvestorPage.css";
import useOnScreen from "../Hooks/OnScreen";
import accessfullhistory from "./Photos/accessfullhistory.svg";
import reducepaperwork from "./Photos/reducepaperwork.svg";
import streamlinemaintenance from "./Photos/streamlinemaintenance.svg";
import trackassetmaintenance from "./Photos/trackassetmaintenance.svg";
import trackexpenses from "./Photos/trackexpenses.svg";
import { Helmet } from 'react-helmet';


function InvestorPage() {
  const ref01 = React.useRef();
  const ref02 = React.useRef();
  const ref03 = React.useRef();
  const ref04 = React.useRef();
  const ref05 = React.useRef();

  const onScreen01 = useOnScreen(ref01, "-100px");
  const [style01, setStyle01] = React.useState("out");
  const onScreen02 = useOnScreen(ref02, "-100px");
  const [style02, setStyle02] = React.useState("out");
  const onScreen03 = useOnScreen(ref03, "-100px");
  const [style03, setStyle03] = React.useState("out");
  const onScreen04 = useOnScreen(ref04, "-100px");
  const [style04, setStyle04] = React.useState("out");
  const onScreen05 = useOnScreen(ref05, "-100px");
  const [style05, setStyle05] = React.useState("out");

  React.useEffect(() => {
    if (onScreen01) {
      setStyle01("in");
    } else {
      setStyle01("out");
    }
  }, [onScreen01]);

  React.useEffect(() => {
    if (onScreen02) {
      setStyle02("in");
    } else {
      setStyle02("out");
    }
  }, [onScreen02]);

  React.useEffect(() => {
    if (onScreen03) {
      setStyle03("in");
    } else {
      setStyle03("out");
    }
  }, [onScreen03]);

  React.useEffect(() => {
    if (onScreen04) {
      setStyle04("in");
    } else {
      setStyle04("out");
    }
  }, [onScreen04]);

  React.useEffect(() => {
    if (onScreen05) {
      setStyle05("in");
    } else {
      setStyle05("out");
    }
  }, [onScreen05]);

  const InvestorPageItems = [
    {
      number: `01`,
      title: "Reduce Paperwork.",
      message: `Tenack is a digital platform available on any device with a browser, so you're able to save all your receipts and manuals digitally, and know exactly where they are when you need them most. No more searching for that one receipt in the back of the junk drawer or worrying about storing important documents in physical binders.`,
      image: reducepaperwork,
      id: ref01,
      style: style01,
    },
    {
      number: `02`,
      title: "Streamline Maintenace Requests.",
      message: `Tenack is the easiest way to manage maintenance requests. You can use the app to get your maintenance requests sorted as fast as possible, and tenants can report issues as they happen. Tradesmen can access information faster than ever before!`,
      image: streamlinemaintenance,
      id: ref02,
      style: style02,
    },
    {
      number: `03`,
      title: "Track Expenses.",
      message: `We believe that you deserve a better way to manage your investments.

      With Tenack, you can now track the real expense of assets and their maintenance, being able to make educated buying decisions for your next investment property. With our platform, it's easy to add and control asset history so you can see how much an investment has cost in the past and what it's likely to cost in the future.
      
      Simply put: Tenack helps you make more strategic financial decisions that you'll never regret.`,
      image: trackexpenses,
      id: ref03,
      style: style03,
    },
    {
      number: `04`,
      title: "Track Asset Maintenace.",
      message: `Tenack gives you the ability to track asset maintenance history, which is critical for keeping your life running smoothly. You can keep track of scheduled maintenance and repairs, as well as record any unexpected incidents that occur. If a tradesman or tenant damages an asset, you'll know about it immediately so that it can be repaired quickly!`,
      image: trackassetmaintenance,
      id: ref04,
      style: style04,
    },
    {
      number: `05`,
      title: "Access to full maintenace history.",
      message: `Tenack is a solution that helps you maintain your property and keep track of the maintenance history. With Tenack, you can create a complete record of all the repairs, upgrades, and inspections that have been done to any item in your property. This is invaluable when selling your home—you can rest assured that the buyer will know exactly what they're getting into when it comes to maintenance.`,
      image: accessfullhistory,
      id: ref05,
      style: style05,
    },
  ];

  return (
    <>
    <Helmet>
                <meta charSet="utf-8" name='Tenack Property Maintenace Software | Made for Investors' content="At Tenack, we believe that a property investor should be able to keep track of their assets in the same way that they keep track of their personal life. We make it easy to store and maintain property assets, so you can see all the things you own at one glance—and make sure they're taken care of." />
                <title>Tenack | Investors</title>
                <link rel="canonical" href="http://tenack.com/investor" />
            </Helmet>

      <div style={{ height: "50px" }} />

      <div className="InvestorPage">
        <div className="list">
          {InvestorPageItems &&
            InvestorPageItems.map((item, ind) => (
              <div className="listitem" key={ind}>
              <div className="number">{`${item.number}.`}</div>
              <h1 className="title">
                {item.title} 
                </h1>
                <p className="message">{item.message}</p>

                <div className="image">
                  <img
                    src={item.image}
                    alt={item.title}
                    className={item.style}
                    ref={item.id}
                  />
               
              </div>
            </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default InvestorPage;
