import React from "react";
import useWindowDimensions from "../Hooks/WindowSize";
import "../Styles/HomePage.css";
import { HomePageCardList } from "./Home Page Cards/HomePageCardList";
import MobileHomePage from "./Mobile Home Page/MobileHomePage";
import { Helmet } from 'react-helmet';


function HomePage() {
 const screen = useWindowDimensions()
  //  const parent  = { width: "100vw", height: `${window.height - 50}px`}



    //const useRef = useRef();
   React.useEffect(() => {

    if (screen.width > 1250) {
let amount = 0

  const scrollContainer = document.getElementById('container');

scrollContainer.addEventListener("wheel", (evt) => {
    evt.preventDefault();

    
       amount = amount + evt.deltaY
    scrollContainer.scrollBy({
      top: 0,
      left: evt.deltaY,
    }) 
});

  
    return () => {
      scrollContainer.removeEventListener("wheel")
    }
    } else {
      return
    }


     
  }, []) 


  
  
      
      

    
    
  return (<>


<Helmet>
                <meta charSet="utf-8" />
                <title>Tenack | Home</title>
                <link rel="canonical" href="http://tenack.com/" />
            </Helmet>


    {screen.width > 1250 ?
    <>
   <div style={{height:'50px'}}  /> 

   <div class="container" id='container' >

   {HomePageCardList.map((item) => (
        <div className="item">
 {item.component}
        </div>
      ))}
</div>
    </>
    :
    <>
   <MobileHomePage />

    </>}
    
    </>);
}

export default HomePage;
