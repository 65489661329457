import { Button, Card } from "@mui/material";
import React, { useRef } from "react";
import useOnScreen from "../../Hooks/OnScreen";
import "../../Styles/Home Page/AboutUsCard.css";
import logo from "../../Images/Logos/tenacklogo.png"
import { useNavigate } from "react-router-dom";

function AboutUsCard() {
  const ref = useRef();
  const navigate = useNavigate();
  const visionOnScreen = useOnScreen(ref, "0px");
  const [vision, setVision] = React.useState("AboutUsCard_img_side");

  React.useEffect(() => {
    if (visionOnScreen) {
      setVision("AboutUsCard_img_side");
    } else {
      setVision("AboutUsCard_img_side_out");
    }
  }, [visionOnScreen]);


  return (
    <div className="AboutUsCard">
      <div className={vision} ref={ref}>
          <div className="AboutUsCard_img_div"> 
              <img alt="Tenack Logo" src={logo} className="AboutUsCard_img"/>
          </div>

      </div>
      <div className="AboutUsCard_text_side">
        <div className="AboutUsCard_text_div">
          <div className="AboutUsCard_text_title">Vision</div>
          <div className="AboutUsCard_text_sub_text">
            Our vision is to provide a flawless and intuitive solution that
            allows our users to experience complete control when maintaining
            assets within both their personal and investment properties.
          </div>
        </div>
        <div className="AboutUsCard_text_div">
          <div className="AboutUsCard_text_title">Mission</div>
          <div className="AboutUsCard_text_sub_text">
            At Tenack our mission is to provide high-quality software and
            technology solutions to increase the efficiency and communication
            between relevant stakeholders predominantly within the maintenance
            and construction industries.
          </div>
        </div>
        <div className="AboutUsCard_text_div">
          <div className="AboutUsCard_text_title">Goals</div>
          <div className="AboutUsCard_text_sub_text">
            Our vision is to provide a flawless and intuitive solution that
            allows our users to experience complete control when maintaining
            assets within both their personal and investment properties.
          </div>
          <Button style={{color:'rgb(18, 100, 100)'}} onClick={((e) => {
            navigate('/about-us')
          })}>
          Learn More...
        </Button>
        </div>
        
      </div>
    </div>
  );
}

export default AboutUsCard;
