export const navigationPathes = [
    {
        icon: '', 
        title:'Tradesman', 
        path: '/tradesman',
        item: 'TradesmanCard'
    },
    {
        icon: '', 
        title:'Investors', 
        path: '/investor',
        item: 'InvestorCard'
    },
    {
        icon: '', 
        title:'Tenants', 
        path: '/tenant',
        item: 'TenantCard'
    },
    {
        icon: '', 
        title:'Everyone', 
        item: 'EveryoneCard',
        path: '/everyone'
    }, 
    {
        icon: '', 
        title:'About Us', 
        path: '/about-us',
    },
   /*  {
        icon: '', 
        title:'Support', 
        path: '/',
    }, */
    {
        icon: '', 
        title:'Pricing', 
        path: '/pricing',
    }


] 

export const sidebarData = [
    {
        icon: '', 
        title:'Investor Login', 
        path: 'https://investor.tenack.com',
        source: 'external'
    },
    {
        icon: '', 
        title:'Tradesman Office', 
        path: '/',
        source: 'internal'
    },
    {
        icon: '', 
        title:'Tradesman Field', 
        path: 'https://field.tenack.com',
        source: 'external'
    },
    {
        icon: '', 
        title:'Tradesman', 
        path: '/',
        source: 'internal'
    },
    {
        icon: '', 
        title:'Investors', 
        path: '/',
        source: 'internal'
    },
    {
        icon: '', 
        title:'Tenants', 
        path: '/',
        source: 'internal'
    },
    {
        icon: '', 
        title:'Everyone', 
        path: '/',
        source: 'internal'
    }, 
    {
        icon: '', 
        title:'About Us', 
        path: '/',
        source: 'internal'
    },
    {
        icon: '', 
        title:'Support', 
        path: '/',
        source: 'internal'
    },
    {
        icon: '', 
        title:'Pricing', 
        path: '/',
        source: 'internal'
    }


] 