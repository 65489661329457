import React from 'react'
import './Styles/LogoCard.css'
import house from '../../Images/Photos/house.png'
import logo from '../../Images/Logos/tenacklogo.png'

function LogoCard() {
  return (
    <>
    <div className='LogoCard_content'> 
    <div className='LogoCard_top'> 

    <div className='LogoCard_div'> 
        <img src={logo} alt='Tenack Property maintenace logo image' className='LogoCard_logo'/>
    </div>
    <div> 
    Property And Asset Managment Software
    </div>


    </div>
    <div className='LogoCard_bottom'> 
    <img src={house} alt='Tenack Property Maintenace House' className='LogoCard_bottom_img' />

</div>
</div>
    </>
  )
}

export default LogoCard