import React from "react";
import "./Styles/TenantPage.css";
import useOnScreen from "../Hooks/OnScreen";
import reducewait from "./Photos/reducewait.svg";
import trackrequests from "./Photos/trackrequests.svg";
import sendrequest from "./Photos/sendrequest.svg";
import reducebackforth from "./Photos/reducebackforth.svg";
import easilylookup from "./Photos/easilylookup.svg";
import { Helmet } from 'react-helmet';


function TenantPage() {
  const ref01 = React.useRef();
  const ref02 = React.useRef();
  const ref03 = React.useRef();
  const ref04 = React.useRef();
  const ref05 = React.useRef();

  const onScreen01 = useOnScreen(ref01, "-100px");
  const [style01, setStyle01] = React.useState("out");
  const onScreen02 = useOnScreen(ref02, "-100px");
  const [style02, setStyle02] = React.useState("out");
  const onScreen03 = useOnScreen(ref03, "-100px");
  const [style03, setStyle03] = React.useState("out");
  const onScreen04 = useOnScreen(ref04, "-100px");
  const [style04, setStyle04] = React.useState("out");
  const onScreen05 = useOnScreen(ref05, "-100px");
  const [style05, setStyle05] = React.useState("out");

  React.useEffect(() => {
    if (onScreen01) {
      setStyle01("in");
    } else {
      setStyle01("out");
    }
  }, [onScreen01]);

  React.useEffect(() => {
    if (onScreen02) {
      setStyle02("in");
    } else {
      setStyle02("out");
    }
  }, [onScreen02]);

  React.useEffect(() => {
    if (onScreen03) {
      setStyle03("in");
    } else {
      setStyle03("out");
    }
  }, [onScreen03]);

  React.useEffect(() => {
    if (onScreen04) {
      setStyle04("in");
    } else {
      setStyle04("out");
    }
  }, [onScreen04]);

  React.useEffect(() => {
    if (onScreen05) {
      setStyle05("in");
    } else {
      setStyle05("out");
    }
  }, [onScreen05]);

  const TenantPageItems = [
    {
      number: `01`,
      title: "Reduced Wait Time.",
      message: `
      Tenack is here to help you get back to your life.
Whether you need a lightbulb replaced or a window fixed,
 Tenack gives you the ability to send a maintenance request
  at anytime allowing you to get on with your life and not 
  wait for office hours.
      
      
     `,
      image: reducewait,
      id: ref01,
      style: style01,
    },
    {
      number: `02`,
      title: "Track Maintenace Requests.",
      message: ` The worst thing about putting in a maintenance request 
      is not knowing where it is in the process. You send your property 
      manager a request, and then… nothing. It could be weeks before they 
      even call someone to fix whatever's wrong with your place.
      Tenack takes care of that problem for you. With Tenack,
       you can rest assured knowing exactly where your maintenance
        request is at: whether it's been received by the property manager,
         if they've contacted a tradesman, if they're on their way to doing 
         so—even if they've already completed the task.`,
      image: trackrequests,
      id: ref02,
      style: style02,
    },
    {
      number: `03`,
      title: "Send Requests at Anytime.",
      message: `No more waiting until the next business day to get 
      your maintenance request taken care of. Tenack can accept your 
      maintenance requests as they happen, so you can rest easy knowing
       that your request is being taken care of as soon as possible.`,
      image: sendrequest,
      id: ref03,
      style: style03,
    },
    {
      number: `04`,
      title: "Reduce Back & forth.",
      message: `Tired of having to explain the same issue over and over
       again to three different people? With Tenack, you can quickly and 
       efficiently send a maintenance request once and be done with it.`,
      image: reducebackforth,
      id: ref04,
      style: style04,
    },
    {
      number: `05`,
      title: "Easily Look Up Past Maintenace Requests.",
      message: `Have you ever had a maintenance request that was 
      taking longer than expected, or been concerned about what 
      happened after your past maintenance request?
      With Tenack, you can easily look up past requests and review 
      the results. We'll let you know who responded, when they
       responded, and how they resolved the issue. You can also get
        notifications when a job is completed!`,
      image: easilylookup,
      id: ref05,
      style: style05,
    },
  ];

  return (
    <>
        <Helmet>
                <meta charSet="utf-8" name="Tenack Property Maintenace Software | Tenants" content="We're on a mission to make staying in your rental as comfortable and convenient as possible. That’s why we've made it easy to report maintenance issues, send feedback, and much more."/>
                <title>Tenack | Tenants</title>
                <link rel="canonical" href="http://tenack.com/tenant" />
            </Helmet>


      <div style={{ height: "50px" }} />

      <div className="TenantPage">
        <div className="list">
          {TenantPageItems &&
            TenantPageItems.map((item, ind) => (
              <div className="listitem" key={ind}>
              <div className="number">{`${item.number}.`}</div>
              <h1 className="title">
                {item.title} 
                </h1>
                <p className="message">{item.message}</p>

                <div className="image">
                  <img
                    src={item.image}
                    alt={item.title}
                    className={item.style}
                    ref={item.id}
                  />
               
              </div>
            </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default TenantPage;
