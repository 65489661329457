import React from "react";
import "./Styles/EveryonePage.css";
import useOnScreen from "../Hooks/OnScreen";
import paperwork from './Photos/keeppaperwork.svg'
import keeptrack from './Photos/trackexpenses.svg'
import completehistory from './Photos/accessfullhistory.svg'
import increaseaccountability from './Photos/increaseMA.svg'
import keepuptodate from './Photos/completehistory.svg'
import {Helmet} from 'react-helmet'



function EveryonePage() {
  const ref01 = React.useRef();
  const ref02 = React.useRef();
  const ref03 = React.useRef();
  const ref04 = React.useRef();
  const ref05 = React.useRef();

  const onScreen01 = useOnScreen(ref01, "-100px");
  const [style01, setStyle01] = React.useState("out");
  const onScreen02 = useOnScreen(ref02, "-100px");
  const [style02, setStyle02] = React.useState("out");
  const onScreen03 = useOnScreen(ref03, "-100px");
  const [style03, setStyle03] = React.useState("out");
  const onScreen04 = useOnScreen(ref04, "-100px");
  const [style04, setStyle04] = React.useState("out");
  const onScreen05 = useOnScreen(ref05, "-100px");
  const [style05, setStyle05] = React.useState("out");

  React.useEffect(() => {
    if (onScreen01) {
      setStyle01("in");
    } else {
      setStyle01("out");
    }
  }, [onScreen01]);

  React.useEffect(() => {
    if (onScreen02) {
      setStyle02("in");
    } else {
      setStyle02("out");
    }
  }, [onScreen02]);

  React.useEffect(() => {
    if (onScreen03) {
      setStyle03("in");
    } else {
      setStyle03("out");
    }
  }, [onScreen03]);

  React.useEffect(() => {
    if (onScreen04) {
      setStyle04("in");
    } else {
      setStyle04("out");
    }
  }, [onScreen04]);

  React.useEffect(() => {
    if (onScreen05) {
      setStyle05("in");
    } else {
      setStyle05("out");
    }
  }, [onScreen05]);

  const EveryonePageItems = [
    {
      number: `01`,
      title: "Keep Paperwork in one place.",
      message: `With Tenack, you can keep all your paperwork in one place. No more hunting around for receipts or part numbers! Tenack is an app that helps you organize all of your documents and receipts. Just take a photo or upload the document and Tenack will store it for you! You can also add notes about the document so it's easy to find later.`,
      image: paperwork,
      id: ref01,
      style: style01,
    },
    {
      number: `02`,
      title: "Keep track of expenses.",
      message: `We believe that you deserve a better way to manage your investments.

      With Tenack, you can now track the real expense of assets and their maintenance, being able to make educated buying decisions for your next investment property. With our platform, it's easy to add and control asset history so you can see how much an investment has cost in the past and what it's likely to cost in the future.
      
      Simply put: Tenack helps you make more strategic financial decisions that you'll never regret.`,
      image: keeptrack,
      id: ref02,
      style: style02,
    },
    {
      number: `03`,
      title: "Have a complete asset history.",
      message: `Tenack is a solution that helps you maintain your property and keep track of the maintenance history. With Tenack, you can create a complete record of all the repairs, upgrades, and inspections that have been done to any item in your property. This is invaluable when selling your home—you can rest assured that the buyer will know exactly what they're getting into when it comes to maintenance.`,

      image: completehistory,
      id: ref03,
      style: style03,
    },
    {
      number: `04`,
      title: "Increased accountability.",
      message: `With Tenack, you can be sure that your property manager and tradesmen are keeping you updated on the status of your requests. Tenack keeps everyone honest and accountable, so you don't have to wonder if the work has been done correctly or if the request has even been actioned. With access to asset history, it takes the guessing game out of it and lets you know what's been done so far.`,
      image: increaseaccountability,
      id: ref04,
      style: style04,
    },
    {
      number: `05`,
      title: "Keep up to date with maintenace.",
      message: `Property assets need regular maintenance, there's no doubt about that, but how do you keep a record of asset history? A binder? A folder? With Tenack, you can create a visual record of what's going on with your property assets, so that you never have to wonder again.`,
      image: keepuptodate,
      id: ref05,
      style: style05,
    },
  ];

  return (
    <>
    <Helmet>
<meta charSet="utf-8" name="Tenack Property Maintenace Software | Made For Everyone" content="Tenack is a tool that allows tradesman, property owners and tenants to interact with a property like never before. Allow maintenance requests and issues to be solved faster than ever."  />
<title>Tenack | Made For Everyone</title>
<link rel="canonical" href="http://tenack.com/everyone" />
</Helmet>

      <div style={{ height: "50px" }} />

      <div className="EveryonePage">
        <div className="list">
          {EveryonePageItems &&
            EveryonePageItems.map((item, ind) => (
              <div className="listitem" key={ind}>
                <div className="number">{`${item.number}.`}</div>
                <h1 className="title">
                  {item.title} 
                  </h1>
                  <p className="message">{item.message}</p>

                  <div className="image">
                    <img
                      src={item.image}
                      alt={item.title}
                      className={item.style}
                      ref={item.id}
                    />
                 
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default EveryonePage;
